<template>
  <div class="page-container">
    <section class="section-settings">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-lg-12 col-sm-12">
            <div class="row pb-4  df">
              <h2 class="col m-1">{{ $t('reservationAssets') }}</h2>
            </div>
            <alert />
            <assets-type-table
              :has-filter="false"
              :loading="loading"
              :items="assetTypes"
              :columns="columns"
              :actions="actions"
              @add="add"
              @edit="edit"
              @remove="showRemoveModal"
            />
          </div>
        </div>
      </div>
    </section>
    <b-modal
      id="edit-modal"
      :on-enter-key-press="save"
      hide-footer
      :title="$t(currentAction)"
      @hidden="resetState"
    >
      <div class="d-block">
        <div class="form-group">
          <label for="name">{{ $t('name') }}:</label>
          <b-form-input
            id="name"
            v-model="assetType.name"
            class="form-control"
            @change="nameChanged"
          />
        </div>
        <div class="form-group">
          <label for="description">{{ $t('description') }}:</label>
          <b-form-input
            id="description"
            v-model="assetType.description"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label for="limit">{{ $t('limit') }}:</label>
          <b-form-input
            id="limit"
            v-model="assetType.limit"
            type="number"
            class="form-control"
          />
          <div v-if="errorMsg.limitError" class="text-danger">
            {{ errorMsg.limitError }}
          </div>
        </div>
        <div class="form-group">
          <label for="serviceEmail">{{ $t('serviceEmail') }}:</label>
          <b-form-input
            id="serviceEmail"
            v-model="assetType.serviceEmail"
            class="form-control"
          />
          <div v-if="errorMsg.serviceEmailError" class="text-danger">
            {{ errorMsg.serviceEmailError }}
          </div>
        </div>
      </div>
      <b-button @click="save">
        {{ $t('save') }}
      </b-button>
    </b-modal>
    <b-modal
      id="modal-remove"
      :on-enter-key-press="() => remove"
      hide-footer
    >
      <template v-slot:modal-title>
        <strong>{{ $t('remove') }}</strong>
      </template>
      <div class="d-block text-center">
        <p>{{ $t('deleteConfirmation') }}</p>
        <b-button
          variant="danger"
          class="m-1"
          @click="$bvModal.hide('modal-remove')"
        >
          {{ $t('no') }}
        </b-button>
        <b-button class="m-1" primary @click="remove">
          {{ $t('yes') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_ALERT } from '@/store/modules/common/mutationTypes'
import AssetsTypeTable from '@/components/common/Table'
import Alert from '@/components/common/Alert'
import BootstrapModalHOC from '@/components/hocs/BootstrapModal'
import OkkuApi from '@/services/OkkuApi'
import { emailRegex } from '@/constants'

const DEFAULT_COLUMNS = [
  {
    class: '',
    header: 'name',
    key: 'name'
  },
  {
    class: '',
    header: 'description',
    key: 'description'
  },
  {
    class: '',
    header: 'limit',
    key: 'limit'
  },
  {
    class: '',
    header: 'serviceEmail',
    key: 'serviceEmail'
  }
]

export default {
  name: 'ReservationAssetsPage',
  components: {
    AssetsTypeTable,
    Alert,
    'b-modal': BootstrapModalHOC
  },
  data() {
    return {
      columns: DEFAULT_COLUMNS,
      actions: [
        {
          name: 'edit',
          class: 'action link-edit text-primary',
          event: 'edit',
          icon: 'edit'
        },
        {
          name: 'remove',
          class: 'action link-edit text-danger',
          event: 'remove',
          icon: 'trash'
        },
        {
          name: 'add',
          class: 'action link-edit',
          event: 'add',
          icon: 'plus',
          global: true
        }
      ],
      timeoutHandler: null,
      action: null,
      assetTypes: [],
      dismissCountDown: 0,
      alertVariant: 'success',
      alertText: '',
      currentAction: '',
      assetType: {},
      loading: true,
      errorMsg: {},
      selectedAsset: {},
      assetTyeNameChanged: false
    }
  },
  mounted() {
    OkkuApi.getAssetTypes().then(assetTypes => {
      this.loading = false
      this.assetTypes = this.decryptServiceProviderEmails(assetTypes)
    })
  },
  updateAlert(variant, text) {
    this.$store.commit(`common/${SET_ALERT}`, {
      seconds: 3,
      text,
      variant: variant || 'success'
    })
  },
  methods: {
    decryptServiceProviderEmails(assets) {
      // Decrypt all service provider email
      assets.forEach(assetType => {
        assetType.serviceEmail = Buffer.from(
          assetType.serviceEmail,
          'base64'
        ).toString('ascii')
      })
      return assets
    },
    resetState() {
      this.assetTyeNameChanged = false
    },
    nameChanged() {
      this.assetTyeNameChanged = true
    },
    add() {
      this.errorMsg = {}
      this.currentAction = 'add'
      this.assetType = {}
      this.$bvModal.show('edit-modal')
    },
    edit(assetType) {
      this.errorMsg = {}
      this.currentAction = 'edit'
      this.assetType = { ...assetType }
      this.$bvModal.show('edit-modal')
    },
    showRemoveModal(record) {
      this.selectedAsset = { ...record }
      this.$bvModal.show('modal-remove')
    },
    async remove() {
      try {
        this.currentAction = 'remove'
        this.assetType = this.selectedAsset
        await OkkuApi.deleteAssetType(this.assetType)
        const assetTypes = await OkkuApi.getAssetTypes()
        this.assetTypes = this.decryptServiceProviderEmails(
          assetTypes
        )
        this.$store.commit(`common/${SET_ALERT}`, {
          seconds: 5,
          text: this.$t('changesSaved'),
          variant: 'success'
        })
        this.$bvModal.hide('modal-remove')
      } catch (error) {
        this.$store.commit(`common/${SET_ALERT}`, {
          seconds: 5,
          text: this.$t('pleaseTryAgain'),
          variant: 'danger'
        })
        this.$bvModal.hide('modal-remove')
      }
    },
    async save() {
      try {
        if (!this.formValidation()) {
          return
        }
        let assetAlreadyPresent = false
        if (
          this.currentAction === 'add' ||
          (this.currentAction === 'edit' && this.assetTyeNameChanged)
        ) {
          this.assetTypes.forEach(asset => {
            if (
              asset.name.toLowerCase() ===
              this.assetType.name.toLowerCase()
            ) {
              assetAlreadyPresent = true
            }
          })
        }
        if (assetAlreadyPresent) {
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 5,
            text: this.$t('assetAlreadyPresent'),
            variant: 'danger'
          })
          this.$bvModal.hide('edit-modal')
          return
        }

        // Encrypt service provider email before API call
        const asset = { ...this.assetType }
        asset.serviceEmail = Buffer.from(asset.serviceEmail).toString(
          'ascii'
        )
        await OkkuApi.upsertAssetType(asset)
        OkkuApi.getAssetTypes().then(assetTypes => {
          this.assetTypes = this.decryptServiceProviderEmails(
            assetTypes
          )
        })
        this.$bvModal.hide('edit-modal')
        this.$store.commit(`common/${SET_ALERT}`, {
          seconds: 5,
          text: this.$t('changesSaved'),
          variant: 'success'
        })
      } catch (error) {
        this.$store.commit(`common/${SET_ALERT}`, {
          seconds: 5,
          text: this.$t('assetAlreadyPresent'),
          variant: 'danger'
        })
        this.$bvModal.hide('edit-modal')
      }
    },
    formValidation() {
      if (
        this.assetType.serviceEmail !== '' &&
        !emailRegex.test(this.assetType.serviceEmail)
      ) {
        this.errorMsg = {
          serviceEmailError: 'Please enter a valid email address'
        }
        return false
      }
      if (!this.assetType.limit) {
        this.errorMsg = {
          limitError: 'Please enter a valid limit'
        }
        return false
      }
      return true
    }
  }
}
</script>
<style lang="scss" scoped></style>
